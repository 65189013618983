<template>
  <b-container fluid>
    <b-modal id="addFolder" size="lg" title="Add Folder" hide-footer no-close-on-backdrop>
      <add-folder @addFolder="getAllFolders() ; $bvModal.hide('addFolder')"></add-folder>
    </b-modal>
    <div class="iq-card p-3">
      <div class="d-flex justify-content-between align-items-center">
        <h5>File Manager</h5>
        <div class="d-flex gap-1">
          <b-button variant="primary" v-b-modal.addFolder>
            <i class="las la-folder-open"></i> Add Folder
          </b-button>
        </div>
      </div>
    </div>
    <div class="iq-card p-3">
      <b-row>
        <b-col md="1" sm="2" v-for="(folder, key) in allFolders" :key="key" class="cursor-pointer" @click="goToFolderDetails(folder.id)">
          <img :src="require('@/assets/images/treeview/t-open-folder.png')" :alt="folder.title" class="w-100"/>
          <p class="text-center text-dark">{{folder.title}}</p>
        </b-col>
<!--        <b-col md="1" class="cursor-pointer">-->
<!--          <img :src="require('@/assets/images/treeview/t-files.png')" class="w-100"/>-->
<!--          <p class="text-center text-dark">title</p>-->
<!--        </b-col>-->
<!--        <b-col md="1" class="cursor-pointer">-->
<!--          <img :src="require('@/assets/images/treeview/ppt.png')" class="w-100"/>-->
<!--          <p class="text-center text-dark">PDF</p>-->
<!--        </b-col>-->
      </b-row>
    </div>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import fileServices from '../services/files'
import addFolder from '../components/addFolder'
export default {
  name: 'reports',
  mounted () {
    core.index()
  },
  components: {
    addFolder
  },
  data () {
    return {
      sound: require('@/assets/images/treeview/Mouse-Click-00-c-FesliyanStudios.com.mp3'),
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      allFolders: [],
      allFiles: []
    }
  },
  methods: {
    getAllFolders () {
      fileServices.getAllFolders().then(res => {
        this.allFolders = res.data.data
      })
    },
    goToFolderDetails (id) {
      this.makeSound()
      this.$router.push({ name: 'folderDetails', params: { id: id } })
    },
    makeSound () {
      const audio = new Audio(this.sound)
      audio.play()
    }
  },
  created () {
    this.getAllFolders()
  }
}
</script>
<style>
.nav-link.router-link-exact-active.router-link-active{
  background: var(--iq-primary) !important;
  color: #fff !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;
}
.gap-1 {
  gap: 1rem;
}
</style>
