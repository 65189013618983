import Api from '@/axios'

export default {
  getAllFolders () {
    return Api().get('folders')
  },
  getAllFilesInFolders (id) {
    return Api().get(`folders/${id}`)
  },
  saveFolder (payload) {
    return Api().post('folders/store', payload)
  },
  saveFile (payload) {
    return Api().post('drives/store', payload)
  },
  move (payload) {
    return Api().post('folders/move', payload)
  }
}
