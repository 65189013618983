<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveFolder)">
        <b-row class="d-flex align-items-center">
          <b-col md="12">
            <input-form :label="'Title'" validate="required" v-model="folder.title" name="Title"/>
          </b-col>
          <b-col md="12" class="d-flex justify-content-between align-items-center">
            <b-form-checkbox v-model="folder.is_active">Is Active</b-form-checkbox>
            <div>
              <b-button variant="primary" class="container_button_blue slideNextArrow" type="submit" v-if="!loadingAction">
                Save
              </b-button>
              <b-button variant="primary" class="container_button_blue slideNextArrow"  v-else>
                <spinner-loading text="Saving" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import fileService from '../services/files'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
export default {
  name: 'add Folder',
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  props: {
    parent: {
      required: false
    }
  },
  data () {
    return {
      folder: {
        title: '',
        shared: 1,
        projects: [JSON.parse(localStorage.getItem('default_project')).id],
        is_active: +true,
        parent: this.parent
      },
      loadingAction: false
    }
  },
  methods: {
    saveFolder () {
      this.loadingAction = true
      fileService.saveFolder(this.folder).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$emit('addFolder')
      }).finally(() => {
        this.loadingAction = false
      })
    }
  }
}
</script>
